import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse } from 'antd';
import { CollapseExpandIcon } from '../../../custom_shared/components';
import './GeneralInformationSubsection.less';

interface Props {
    subsectionKey: string;
    subsectionName: string;
    children: React.ReactNode;
    addSubsectionRef: (subsectionKey: string, subsectionRef: React.RefObject<HTMLDivElement>) => void;
}

const GeneralInformationSubsection: React.FC<Props> = (props: Props) => {
    const { subsectionKey, subsectionName, children, addSubsectionRef } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addSubsectionRef(subsectionKey, ref);
    }, [subsectionKey, addSubsectionRef]);

    return (
        <div ref={ref} className="general-information-subsection-container">
            <Collapse bordered={false} defaultActiveKey={subsectionKey} expandIcon={CollapseExpandIcon}>
                <Collapse.Panel
                    className="subsection-collapse-panel"
                    header={<span className="header-title">{subsectionName}</span>}
                    key={subsectionKey}
                >
                    {children}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(GeneralInformationSubsection);
