import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Row, Col } from 'antd';
import { StyleUtils } from '../../misc';
import { GetComponentRowsFunction } from '../../componentTypes';
import { SectionModel, SectionsTabModel } from '../../models';
import { CollapseExpandIcon } from '../../../custom_shared/components';
import './SectionRenderer.less';

interface Props {
    tab: SectionsTabModel;
    section: SectionModel;
    getComponentRows: GetComponentRowsFunction;
    addSectionRef: (sectionId: string, sectionRef: React.RefObject<HTMLDivElement>) => void;
}

const SectionRenderer: React.FC<Props> = (props: Props) => {
    const { tab, section, getComponentRows, addSectionRef } = props;

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        addSectionRef(section.id, ref);
    }, [section.id, addSectionRef]);

    return (
        <div ref={ref} className="sections-layout-section-renderer-container">
            <Collapse
                key={section.id}
                activeKey={section.expanded ? [section.id] : []}
                onChange={section.toggleExpanded}
                bordered={false}
                expandIcon={CollapseExpandIcon}
            >
                <Collapse.Panel
                    className="section-collapse-panel"
                    header={<span className="header-title">{section.name}</span>}
                    key={section.id}
                >
                    {getComponentRows(section.inputs, { TabId: tab.id, SectionId: section.id }).map(
                        (componentRow, index) => (
                            <Row key={index} gutter={10}>
                                {componentRow.map(component => (
                                    <Col key={component.key} style={StyleUtils.getTabColumnStyle(8)}>
                                        {component}
                                    </Col>
                                ))}
                            </Row>
                        )
                    )}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(SectionRenderer);
