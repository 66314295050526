import { makeObservable, observable, runInAction } from 'mobx';
import { ContractsIngestionRuntimeInputDto } from '../../types';
import { InputBaseModel, SourceModelBase, StaticSourceModel, TabBaseModel } from '..';
import { SourceProvider } from '../../misc';
import { TypeUtils } from '../../../custom_shared/misc';

export default class InputModelWithSource extends InputBaseModel {
    sourceProvider: SourceProvider;

    constructor(tab: TabBaseModel, data: ContractsIngestionRuntimeInputDto, source?: SourceModelBase) {
        super(tab, data);

        this.createSourceProvider(source);

        makeObservable(this, {
            sourceProvider: observable
        });
    }

    async fetchSourceOptions(showLoading: boolean) {
        if (showLoading) {
            this.sourceProvider.setLoading(true);
        }

        try {
            if (this.parentInput) {
                await this.sourceProvider.getSourceOptionsByParent(this.parentInput.value);
            } else {
                await this.sourceProvider.getSourceOptions();
            }

            this.syncValueWithOptions();
        } catch (e) {
            console.error(`Error while fetching source options for input '${this.id}'.`, e);
        } finally {
            if (showLoading) {
                this.sourceProvider.setLoading(false);
            }
        }
    }

    private syncValueWithOptions(): void {
        if (TypeUtils.isStringArray(this.value)) {
            return this.setValue(this.value.filter(v => this.sourceProvider.values.includes(v)));
        }

        if (TypeUtils.isString(this.value) && !this.sourceProvider.values.includes(this.value)) {
            return this.setValue(null);
        }
    }

    private createSourceProvider(source?: SourceModelBase) {
        runInAction(() => {
            if (source) {
                this.sourceProvider = new SourceProvider(source);
                return;
            }

            console.warn(`Source not found for input '${this.id} ${this.guid}'`);
            this.sourceProvider = new SourceProvider(new StaticSourceModel({ id: '', type: '', link: null, data: [] }));
        });
    }
}
