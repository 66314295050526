import { z } from 'zod';
import {
    InputModelWithSource,
    LinkedSourceModel,
    LayoutSettingsBaseModel,
    BasicLayoutSettingsModel,
    LayersLayoutSettingsModel,
    LayersWithSectionsLayoutSettingsModel,
    SectionsLayoutSettingsModel,
    BasicTabModel,
    LayersTabModel,
    LayersWithSectionsTabModel,
    SectionsTabModel
} from '../models';
import { LayerRange, SectionRange } from '../types';

export default class InstanceUtils {
    static numberOfLayersSchema = z.object({
        numberOfLayers: z.number()
    });

    static numberOfSectionsSchema = z.object({
        numberOfSections: z.number()
    });

    static layerRangeSchema = z.object({
        minNumberOfLayers: z.number(),
        maxNumberOfLayers: z.number()
    });

    static sectionRangeSchema = z.object({
        minNumberOfSections: z.number(),
        maxNumberOfSections: z.number()
    });

    static hasLayers(value: unknown): value is z.infer<typeof InstanceUtils.numberOfLayersSchema> {
        return InstanceUtils.numberOfLayersSchema.safeParse(value).success;
    }

    static hasSections(value: unknown): value is z.infer<typeof InstanceUtils.numberOfSectionsSchema> {
        return InstanceUtils.numberOfSectionsSchema.safeParse(value).success;
    }

    static hasLayerRange(value: unknown): value is LayerRange {
        return InstanceUtils.layerRangeSchema.safeParse(value).success;
    }

    static hasSectionRange(value: unknown): value is SectionRange {
        return InstanceUtils.sectionRangeSchema.safeParse(value).success;
    }

    static isInputWithSource(value: unknown): value is InputModelWithSource {
        return value instanceof InputModelWithSource;
    }

    static isLinkedSource(value: unknown): value is LinkedSourceModel {
        return value instanceof LinkedSourceModel;
    }

    static isLayoutSettings(value: unknown): value is LayoutSettingsBaseModel {
        return value instanceof LayoutSettingsBaseModel;
    }

    static isBasicLayoutSettings(value: unknown): value is BasicLayoutSettingsModel {
        return value instanceof BasicLayoutSettingsModel;
    }

    static isLayersLayoutSettings(value: unknown): value is LayersLayoutSettingsModel {
        return value instanceof LayersLayoutSettingsModel;
    }

    static isLayersWithSectionsLayoutSettings(value: unknown): value is LayersWithSectionsLayoutSettingsModel {
        return value instanceof LayersWithSectionsLayoutSettingsModel;
    }

    static isSectionsLayoutSettings(value: unknown): value is SectionsLayoutSettingsModel {
        return value instanceof SectionsLayoutSettingsModel;
    }

    static isBasicTab(value: unknown): value is BasicTabModel {
        return value instanceof BasicTabModel;
    }

    static isLayersTab(value: unknown): value is LayersTabModel {
        return value instanceof LayersTabModel;
    }

    static isLayersWithSectionsTab(value: unknown): value is LayersWithSectionsTabModel {
        return value instanceof LayersWithSectionsTabModel;
    }

    static isSectionsTab(value: unknown): value is SectionsTabModel {
        return value instanceof SectionsTabModel;
    }
}
