import { makeObservable, observable, action, reaction, autorun } from 'mobx';
import axios, { CancelTokenSource } from 'axios';
import { message } from 'antd';
import { RenewalSuggestionsStateDto, RenewalSuggestionDto, RenewalSuggestionRequest } from '../types';
import { Disposer, TypeUtils } from '../../custom_shared/misc';
import { InputIds, InstanceUtils } from '../misc';
import { ContractsIngestionService } from '../services';
import AppStore from './AppStore';
import TabStore from './TabStore';
import SessionStore from './SessionStore';
import ContractSubmissionStore from './ContractSubmissionStore';
import SettingsStore from './SettingsStore';

export default class RenewalSuggestionsStore extends Disposer {
    state: RenewalSuggestionsStateDto = {
        searchDisabled: false,
        suggestions: []
    };

    selectedSuggestion: RenewalSuggestionDto | null = null;

    modalVisible: boolean = false;

    controlsVisible: boolean = false;

    searchingSuggestions: boolean = false;

    error: string = '';

    private cancelTokenSource: CancelTokenSource | null = null;

    constructor(
        private readonly appStore: AppStore,
        private readonly tabStore: TabStore,
        private readonly sessionStore: SessionStore,
        private readonly contractSubmissionStore: ContractSubmissionStore,
        private readonly settingsStore: SettingsStore,
        private readonly service: ContractsIngestionService
    ) {
        super();

        makeObservable(this, {
            state: observable,
            selectedSuggestion: observable,
            modalVisible: observable,
            controlsVisible: observable,
            searchingSuggestions: observable,
            error: observable,
            setState: action,
            setModalVisible: action,
            setControlsVisible: action,
            setSelectedSuggestion: action,
            setSearchingSuggestions: action,
            setError: action
        });

        this.reactions.push(
            reaction(
                () => this.state.suggestions,
                suggestions => {
                    if (suggestions.length) {
                        this.setSelectedSuggestion(suggestions[0]);
                    } else {
                        this.setSelectedSuggestion(null);
                    }
                }
            )
        );

        this.reactions.push(
            autorun(r => {
                if (this.state && !this.sessionStore.isInitializing) {
                    this.initialize();
                    r.dispose();
                }
            })
        );

        this.getState();
    }

    setState(state: RenewalSuggestionsStateDto) {
        this.state = state;
    }

    setModalVisible(modalVisible: boolean) {
        this.modalVisible = modalVisible;
    }

    setControlsVisible(controlsVisible: boolean) {
        this.controlsVisible = controlsVisible;
    }

    setSelectedSuggestion(selectedSuggestion: RenewalSuggestionDto | null) {
        this.selectedSuggestion = selectedSuggestion;
    }

    setSearchingSuggestions(searchingSuggestions: boolean) {
        this.searchingSuggestions = searchingSuggestions;
    }

    setError(error: string) {
        this.error = error;
    }

    async initialize() {
        // Only basic layout is supported
        if (!this.settingsStore.isBasicLayoutSettings) {
            return;
        }

        if (!this.state || this.state.searchDisabled || this.contractSubmissionStore.contractSendingOrSent) {
            return;
        }

        this.setControlsVisible(true);

        if (!this.state.suggestions.length) {
            this.searchRenewalSuggestions();
        }
    }

    async getState() {
        const resp = await this.service.getRenewalSuggestionsState(
            this.appStore.sessionExtension,
            this.appStore.sessionId
        );

        if (resp.isOk()) {
            resp.map(state => this.setState(state));
        }
    }

    async searchRenewalSuggestions() {
        try {
            this.setError('');
            this.cancelRenewalSuggestionsRequest();

            const suggestionsRequest = this.createRenewalSuggestionsRequest();

            if (!suggestionsRequest) {
                message.info('Please fill in contract data to search renewal suggestions');
                return this.clearSuggestions();
            }

            this.setSearchingSuggestions(true);
            this.cancelTokenSource = axios.CancelToken.source();

            const resp = await this.service.searchRenewalSuggestions(
                this.appStore.sessionExtension,
                this.appStore.sessionId,
                suggestionsRequest,
                this.cancelTokenSource.token
            );

            if (!resp.isOk()) {
                this.setError('Failed to retrieve contract details');
                return this.clearSuggestions();
            }

            resp.map(state => this.setState(state));
        } finally {
            this.setSearchingSuggestions(false);
        }
    }

    async disableRenewalSuggestionsSearch() {
        this.setControlsVisible(false);
        this.setState({ ...this.state, searchDisabled: true });

        const resp = await this.service.disableRenewalSuggestionsSearch(
            this.appStore.sessionExtension,
            this.appStore.sessionId
        );

        if (resp.isOk()) {
            resp.map(state => this.setState(state));
        }
    }

    retryRenewalSuggestionsSearch() {
        this.setControlsVisible(true);
        this.setState({ ...this.state, searchDisabled: false });
        this.searchRenewalSuggestions();
    }

    clearSuggestions() {
        this.setState({ ...this.state, suggestions: [] });
    }

    cancelRenewalSuggestionsSearch() {
        this.cancelRenewalSuggestionsRequest();
        this.setSearchingSuggestions(false);
        this.setControlsVisible(false);
    }

    dispose() {
        this.disposeReactions();
    }

    private cancelRenewalSuggestionsRequest() {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel();
        }
    }

    private createRenewalSuggestionsRequest(): RenewalSuggestionRequest | null {
        const generalInformationTab = this.tabStore.generalInformationTab;
        const limitsTab = this.tabStore.limitsTab;

        // Only basic layout is supported
        if (!InstanceUtils.isBasicTab(generalInformationTab) || !InstanceUtils.isBasicTab(limitsTab)) {
            return null;
        }

        const {
            UnderwritingYear,
            BusinessType,
            PeriodStart,
            Cedent,
            Currency,
            MainClass,
            Broker,
            TypeOfParticipation
        } = InputIds.GeneralInformation;

        const { Cover, Excess } = InputIds.Limits;

        const generalInformationInputs = generalInformationTab.inputs;
        const limitsInputs = limitsTab.inputs;
        const underwritingYear = generalInformationInputs.find(i => i.id === UnderwritingYear)?.value;
        const periodStart = generalInformationInputs.find(i => i.id === PeriodStart)?.value;
        const businessType = generalInformationInputs.find(i => i.id === BusinessType)?.value;
        const cedent = generalInformationInputs.find(i => i.id === Cedent)?.value;
        const currency = generalInformationInputs.find(i => i.id === Currency)?.value;
        const mainClass = generalInformationInputs.find(i => i.id === MainClass)?.value;
        const typeOfParticipation = generalInformationInputs.find(i => i.id === TypeOfParticipation)?.value;
        const broker = generalInformationInputs.find(i => i.id === Broker)?.value;
        const limitCover = limitsInputs.find(i => i.id === Cover)?.value;
        const limitExcess = limitsInputs.find(i => i.id === Excess)?.value;

        const suggestionsRequest = {
            underwritingYear: TypeUtils.isNumber(underwritingYear) ? underwritingYear : undefined,
            typeOfBusiness: TypeUtils.isString(businessType) ? businessType : undefined,
            insuredPeriodStart: TypeUtils.isString(periodStart) ? periodStart : undefined,
            cedent: TypeUtils.isString(cedent) ? cedent : undefined,
            currency: TypeUtils.isString(currency) ? currency : undefined,
            mainClasses: TypeUtils.isStringArray(mainClass) ? mainClass : [],
            typeOfParticipation: TypeUtils.isString(typeOfParticipation) ? typeOfParticipation : undefined,
            broker: TypeUtils.isString(broker) ? broker : undefined,
            limitCover: this.parseCoverValue(limitCover),
            limitExcess: TypeUtils.isNumber(limitExcess) ? limitExcess : undefined
        };

        if (Object.values(suggestionsRequest).every(value => value === undefined)) {
            return null;
        }

        return suggestionsRequest;
    }

    private parseCoverValue(value: unknown) {
        if (!TypeUtils.isString(value)) {
            return undefined;
        }

        // Cover value also can be "Unlimited", for request we only use amount
        const parsedCover = parseFloat(value);
        return TypeUtils.isNumber(parsedCover) ? parsedCover : undefined;
    }
}
