import { makeObservable, action, computed, observable, reaction, autorun } from 'mobx';
import { message } from 'antd';
import { ContractStatus, ContractSubmissionRequestStateDto } from '../types';
import { Disposer, TypeUtils } from '../../custom_shared/misc';
import { ContractsIngestionService } from '../services';
import ActionStore from './ActionStore';
import TabStore from './TabStore';
import SessionStore from './SessionStore';
import FormStore from './FormStore';

export default class ContractSubmissionStore extends Disposer {
    contractStatus: ContractStatus = null;
    submissionRequestState: ContractSubmissionRequestStateDto = {
        status: null
    };

    private submissionRequestInterval: ReturnType<typeof setInterval> | null = null;

    constructor(
        private readonly formStore: FormStore,
        private readonly tabStore: TabStore,
        private readonly sessionStore: SessionStore,
        private readonly actionStore: ActionStore,
        private readonly service: ContractsIngestionService
    ) {
        super();

        makeObservable(this, {
            contractStatus: observable,
            submissionRequestState: observable,
            setContractStatus: action,
            setSubmissionRequestState: action,
            contractSending: computed,
            contractSent: computed,
            contractSendingOrSent: computed,
            requestProcessingDelayed: computed,
            flowResponse: computed
        });

        this.reactions.push(
            reaction(
                () => this.sessionStore.response,
                () => {
                    if (this.sessionStore.response && !this.sessionStore.inProgress) {
                        this.setContractStatus(this.sessionStore.response.contractStatus);
                    }
                }
            )
        );

        // TODO: create iota plugin webosocket connection to get updates
        this.reactions.push(
            autorun(() => {
                if (this.submissionRequestInterval) {
                    clearInterval(this.submissionRequestInterval);
                }

                // Once we get the delayed status, we don't need to keep checking
                if (this.requestProcessingDelayed) {
                    return;
                }

                if (this.contractSending) {
                    this.submissionRequestInterval = setInterval(() => {
                        this.getContractSubmissionRequestState();
                    }, 1000);
                }
            })
        );

        this.getContractSubmissionRequestState();
    }

    setContractStatus(contractStatus: ContractStatus) {
        this.contractStatus = contractStatus;
    }

    setSubmissionRequestState(submissionRequestState: ContractSubmissionRequestStateDto) {
        this.submissionRequestState = submissionRequestState;
    }

    async getContractSubmissionRequestState() {
        const response = await this.service.getContractSubmissionRequestState(
            this.sessionStore.sessionExtension,
            this.sessionStore.sessionId
        );

        if (response.isOk()) {
            response.map(state => this.setSubmissionRequestState(state));
        }
    }

    get contractSending() {
        return this.contractStatus == 'ContractSending';
    }

    get contractSent() {
        return this.contractStatus == 'ContractSent';
    }

    get contractSendingOrSent() {
        return this.contractSending || this.contractSent;
    }

    get requestProcessingDelayed() {
        return this.submissionRequestState.status === 'RequestProcessingDelayed';
    }

    get flowResponse() {
        const flowResponse = this.sessionStore.response?.flowResponse;

        if (!flowResponse) {
            return null;
        }

        return {
            contractTitle: TypeUtils.isString(flowResponse.contractTitle) ? flowResponse.contractTitle : null,
            programId: TypeUtils.isString(flowResponse.programId) ? flowResponse.programId : null,
            businessIds: TypeUtils.isStringArray(flowResponse.businessIds) ? flowResponse.businessIds : [],
            isRenewal: TypeUtils.isBoolean(flowResponse.isRenewal) ? flowResponse.isRenewal : false
        };
    }

    async sendContract(): Promise<void> {
        if (this.contractSendingOrSent) {
            return;
        }

        const validations = await this.formStore.validateForms();

        let allValid = true;

        validations.forEach(validation => {
            if (!validation.success) {
                allValid = false;
            }

            const tab = this.tabStore.tabs.find(t => t.id === validation.tabId);

            if (tab) {
                tab.setHasError(!validation.success);
            }
        });

        if (allValid) {
            this.setContractStatus('ContractSending');
            return this.actionStore.sendContract();
        }

        message.error('Please complete all required fields to proceed');
    }

    dispose() {
        this.disposeReactions();
    }
}
