import { TabBaseModel, InputModel, InputModelWithSource, SourceModelBase } from '../models';
import { ContractsIngestionRuntimeInputDto } from '../types';

export default class InputFactory {
    static createInputs(tab: TabBaseModel, inputs: ContractsIngestionRuntimeInputDto[], sources: SourceModelBase[]) {
        return inputs.map(input => InputFactory.createInput(tab, input, sources));
    }

    static createInput(tab: TabBaseModel, input: ContractsIngestionRuntimeInputDto, sources: SourceModelBase[]) {
        if (input.sourceId) {
            return new InputModelWithSource(
                tab,
                input,
                sources.find(s => s.id === input.sourceId)
            );
        }

        return new InputModel(tab, input);
    }

    static createOrUpdateInputs(
        tab: TabBaseModel,
        existingInputs: InputModel[],
        inputs: ContractsIngestionRuntimeInputDto[],
        sources: SourceModelBase[]
    ) {
        for (const input of inputs) {
            const existingInput = existingInputs.find(i => i.id === input.id);

            if (existingInput) {
                existingInput.update(input);
            } else {
                existingInputs.push(InputFactory.createInput(tab, input, sources));
            }
        }

        return existingInputs.filter(input => inputs.some(i => i.id === input.id));
    }
}
